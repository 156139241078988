import { memo, useState, useLayoutEffect, useMemo } from 'react';
import { CustomerProfile, ProfileGear } from 'types';
import {NextBackgroundImage, NextIcon} from 'components/NextImage';
import { NextLink } from 'components/NextLink';
import { ItemImageNotes } from 'components/ItemImageNotes';
import { GearSizesWithBrands } from './GearSizesWithBrands';
import { GearOwnerInfo } from './GearOwnerInfo';
import { GearActions } from './GearActions';
import { USER_COVER_PLACEHOLDER_LINK } from 'consts';

import cx from 'classnames';
import styles from './GearCard.module.scss';
import CharityLogo from '../CharityLogo';

export type GearCardProps = ProfileGear & {
  toggleFavorite: (slug: string, isFavorite: boolean) => Promise<boolean>;
  onComment: (slug: string) => void;
  onShare: (slug: string, owner: Partial<CustomerProfile>, isSold: boolean) => void;
};

export const GearCard = memo(function WrappedGearCard({
  brand,
  country,
  comments_count: commentsCount,
  cover_image: coverImage,
  in_my_favorites: inMyFavorites,
  is_sold: isSold,
  charity_percent,
  onComment,
  onShare,
  original_price,
  price,
  currency,
  sizes,
  slug,
  title,
  toggleFavorite = async () => false,
  type,
  user,
  users_favorites_count: userFavoritesCount,
  shape = 'full',
}: GearCardProps) {
  const [fav, setFav] = useState(inMyFavorites);
  const [favoriteCount, setFavoriteCount] = useState(userFavoritesCount);
  const gearLink = `/gear/${slug}`;
  const isCharity = useMemo(() => charity_percent===100, [charity_percent])

  useLayoutEffect(() => {
    setFav(inMyFavorites)
  }, [inMyFavorites])

  const calculateFavorites = (newFavorite: boolean) => {
    setFavoriteCount(newFavorite ? favoriteCount + 1 : favoriteCount - 1);
  };

  const favoriteAction = {
    icon: fav ? '/icons/StarBlue.svg' : '/icons/weather/StarDark.svg',
    label: favoriteCount,
    callback: async () => {
      const success = await toggleFavorite(slug, fav);
      success && setFav((favorite) => {
        calculateFavorites(!favorite)
        return !favorite
      });
    },
  };

  const commentAction = {
    icon: '/icons/communications/ChatCircleDark.svg',
    label: commentsCount,
    callback: () => onComment(slug),
  };

  const shareAction = {
    icon: '/icons/communications/ShareNetworkDark.svg',
    label: '',
    callback: () => onShare(slug, user, !!isSold),
  };

  const getPriceElement = (
    price: string | number,
    currency: string,
    isRent: boolean,
    hourlyPrice?: number,
    isHourlyRent?: boolean,
    rentDayLength: number = 0,
  ) => {
    if (!isRent) {
      return <h2 className={styles.productPrice}>{`$${price} ${currency}`}</h2>;
    }
    if (hourlyPrice && isHourlyRent) {
      return (
        <h2 className={styles.productPrice}>
          {`$${hourlyPrice}`}
          <span>/h</span>
        </h2>
      );
    }
    if (hourlyPrice && rentDayLength <= 1) {
      return (
        <h2 className={styles.productPrice}>
          {`$${price}`}
          <span>/d, </span> {` $${hourlyPrice} ${currency}`}
          <span>/h</span>
        </h2>
      );
    }
    return (
      <h2 className={styles.productPrice}>
        {`$${price} ${currency}`}
        <span>/day</span>
      </h2>
    );
  };

  return (
    <div className={cx(styles.container, styles[shape], 'gear-card')}>
      <NextLink href={gearLink} useDirectLink={true}>
        <>
          <NextBackgroundImage src={coverImage || USER_COVER_PLACEHOLDER_LINK} customClass={styles.coverImage} isCharity={isCharity}>
            {isSold ? <ItemImageNotes withCover={true} texting="This item has been sold." /> : null}
          </NextBackgroundImage>
        </>
      </NextLink>
      <div className={styles.info}>
        <NextLink href={gearLink} useDirectLink={true}>
          <p className={styles.productTitle}>{title}</p>
        </NextLink>
        <div className={styles.priceLine}>
          <div className={styles.priceContainer}>
            {getPriceElement(price, currency,type === 'rent')}
            {!!original_price && <p className={styles.originalPrice}>{`$${original_price}`}</p>}
          </div>
          {(country === 'CAN' || country === 'CA') && <NextIcon src="/icons/Maple.svg" size={32} />}
        </div>
        <GearSizesWithBrands sizes={sizes} brand={brand} />
        <GearOwnerInfo user={user} />
      </div>
      <GearActions actions={[favoriteAction, commentAction, shareAction]} />
    </div>
  );
});
